import * as React from "react"
import { Link } from "gatsby"
import {Helmet} from "react-helmet";

import logo from '../images/celestia-logo.svg'
import Image from "./imageComponent";

const navigation = [
    {
        text: "Услуги",
        url: "/services/",
        type: 'internal'
    },
    {
        text: "Портфолио",
        url: "/cases/",
        type: 'internal'
    },
    {
        text: "Команда",
        url: "/team/",
        submenus: [
            {
                text: "Наша команда",
                subtext: "Команда",
                url: "/team/",
                icon: "menu/team.svg",
                type: 'internal'
            },
            {
                text: "Открытые Вакансии",
                subtext: "Вакансии",
                url: "/careers/",
                icon: "menu/careers.svg",
                type: 'internal'
            }
        ]
    },
    {
        text: "Контакты",
        url: "",
        submenus: [
            {
                text: "Свзяь с нами",
                subtext: "Контакты",
                url: "/contacts/",
                icon: "menu/faq.svg",
                type: 'internal'
            },
            {
                text: "Присоединяйтесь в нашу группу Telegram",
                subtext: "Telegram",
                url: "https://t.me/simbios_agency",
                icon: "menu/telegram.svg",
                type: 'external'
            },
        ]
    },
]

class Header extends React.Component {
    componentDidMount() {
        window.addEventListener("scroll", this.toggleHeaderClass);
        this.toggleHeaderClass();
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.toggleHeaderClass);
    }

    toggleHeaderClass = () => {
        if (window.scrollY > 100) {
            document.getElementById("header").classList.add("blur");
        } else {
            document.getElementById("header").classList.remove("blur");
        }
    };


    toggleMenu = () => {
        if (document.getElementById("hamburger").classList.contains('is-active')) {
            document.getElementById("header").classList.remove("menu-opened");
            document.getElementById("hamburger").classList.remove("is-active");
        }else{
            document.getElementById("hamburger").classList.add("is-active");
            document.getElementById("header").classList.add("menu-opened");
        }
    }

    render() {
        return (
            <>
            <header id={'header'}>
                <Helmet>
                    <title>Simbios | Digital Agency</title>
                </Helmet>
                <div className={'blurry'}/>
                <div className={'container'}>
                    <button id={'hamburger'} className="hamburger hamburger--slider" type="button" onClick={this.toggleMenu}>
                      <span className="hamburger-box">
                        <span className="hamburger-inner"/>
                      </span>
                    </button>
                    <div className={'logo'}>
                        <Link to="/"><img src={logo} alt="Celestia"/></Link>
                    </div>
                    <nav>
                        <ul>
                            {navigation.map((link, index) => (
                                <li key={index}>
                                    {link.submenus ? <div className={'hasSubmenu'}>{link.text} <i className={'icon-dropdown'}/></div> : link.type === 'external' ?
                                        <a href={link.url} target={'_blank'} rel={'noreferrer'}>{link.text}</a> : <Link to={`${link.url}`} activeClassName="active">{link.text} {link.submenus && <i className={'icon-dropdown'}/>}</Link>}
                                    {link.submenus &&
                                        <div className={'submenu'}>
                                            <ul>
                                                {link.submenus.map((submenu, index) => (
                                                    <li key={index}>
                                                        {submenu.type === 'external' ?
                                                            (<a href={submenu.url} target={'_blank'} rel="noreferrer">
                                                            <div className={'row d-table w-100'}>
                                                                <div className={'col-2 align-middle d-table-cell'}>
                                                                    <div className={'icon'}>
                                                                        <Image alt={submenu.text} filename={submenu.icon} />
                                                                    </div>
                                                                </div>
                                                                <div className={'col-10 align-middle d-table-cell'}>
                                                                    <div className={'subtitle'}>{submenu.subtext}</div>
                                                                    <div className={'title'}>{submenu.text}</div>
                                                                </div>
                                                            </div>
                                                        </a>)
                                                            :
                                                            (<Link to={submenu.url} >
                                                            <div className={'row d-table w-100'}>
                                                                <div className={'col-2 align-middle d-table-cell'}>
                                                                    <div className={'icon'}>
                                                                        <Image alt={submenu.text} filename={submenu.icon} />
                                                                    </div>
                                                                </div>
                                                                <div className={'col-10 align-middle d-table-cell'}>
                                                                    <div className={'subtitle'}>{submenu.subtext}</div>
                                                                    <div className={'title'}>{submenu.text}</div>
                                                                </div>
                                                            </div>
                                                        </Link>)}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    }

                                </li>
                            ))}
                        </ul>
                    </nav>
                    <div className={'clear'}/>
                </div>
            </header>
                </>
        );
    }
}

export default Header
