import * as React from "react"
import { Link } from "gatsby"

import FooterBox from "../components/footer-box";
import Discord from "./socials/discord";
import Github from "./socials/github";
import Email from "./socials/email";
import Twitter from "./socials/twitter";
import Youtube from "./socials/youtube";

import addToMailchimp from 'gatsby-plugin-mailchimp'

import ReactModal from 'react-modal'


ReactModal.setAppElement('#___gatsby')

const navigation = [
    {
        text: "Главная",
        url: "/",
        type: 'internal'
    },{
        text: "Контакты",
        url: "/contacts/",
        type: 'internal'
    },{
        text: "Услуги",
        url: "/services/",
        type: 'internal'
    },{
        text: "Telegram",
        url: "https://t.me/simbios_agency",
        type: 'external'
    },{
        text: "Портфолио",
        url: "/cases/",
        type: 'internal'
    },{
        text: "",
        url: "/faq/",
        type: 'internal'
    },{
        text: "Команда",
        url: "/team/",
        type: 'internal'
    },{
        text: "",
        url: "https://company-223625.frontify.com/d/JoSwaZS4Mjpj",
        type: 'external'
    },{
        text: "Ваканcии",
        url: "/careers/",
        type: 'internal'
    },
    {
        text: "",
        url: "https://forum.celestia.org",
        type: 'external'
    },
]

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email : '',
            listFields : {
                'group[57543]': '1'
            },
            isModalOpen: false,
            popupTitle: '',
            msg: ''
        };
    }
    handleModalOpen = event => {
        this.setState({ isModalOpen: true })
        document.body.style.overflow = 'hidden';
    }

    handleModalClose = event => {
        this.setState({ isModalOpen: false })
        document.body.style.overflow = 'unset';
    }


    mailchimp(url){
        addToMailchimp(this.state.email, this.state.listFields, url) // listFields are optional if you are only capturing the email address.
            .then(data => {
                this.setState({msg:data.msg});
                if(data.result === 'error' && data.msg.includes("is already subscribed")){
                    this.setState({success:true});
                    this.setState({isModalOpen:true});
                    this.setState({popupTitle:'Thank you!'});
                    this.setState({msg:'Thank you for subscribing!'});
                }else{
                    if(data.result === 'success'){
                        this.setState({success:true});
                        this.setState({isModalOpen:true});
                        this.setState({popupTitle:'Thank you!'});
                        this.setState({msg:this.state.msg});
                    }else{
                        this.setState({isModalOpen:true});
                        this.setState({popupTitle:'Error'});
                    }
                }
                //console.log(data)
            })
            .catch(() => {
            })
    }

    _handleSubmit = e => {
        e.preventDefault();
        const listFields = {};

        listFields['group[57543][1]'] = 1

        this.setState(prevState => ({
            listFields
        }),()=> {
            if(this.state.email){
                this.mailchimp('https://celestia.us6.list-manage.com/subscribe/post?u=cde2461ba84f5279fff352829&amp;id=8d165e36d3')
            }
        })
    }


    change = (e) => {
        e.preventDefault();
        this.setState({ email: e.target.value })
    };

    render() {
        return (
            <footer id={'footer'}>
                <div className={'container'}>

                    <FooterBox footerBoxes={this.props.FooterBoxes}/>

                    <div className={'row py-5 mt-5'}>
                        <div className={'col col-12 col-lg-4 pe-5'}>
                            <div className={'title'}>Мы тут!</div>

                            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A9f8c17c53c5be5f77998c4bb3566edcf8b3a2b618cc16887407e6e820542c210&amp;source=constructor" width="380" height="240"></iframe>

                        </div>
                        <div className={'col col-12 col-lg-8 ps-3 pt-5 pt-lg-0 ps-lg-5'}>
                            <div className={'row'}>
                                <div className={'col col-12 col-sm-6 col-lg-6'}>
                                    <div className={'col-title'}>Навигация</div>
                                    <nav>
                                        <ul>
                                            {navigation.map(link => (
                                                <li key={`${link.url}`}>
                                                    {link.type === 'external' ? <a href={`${link.url}`} target={'_blank'} rel={'noreferrer'}>{link.text}</a> : <Link to={`${link.url}`}>{link.text}</Link>}
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                                <div className={'col col-12 col-sm-6 col-lg-6 align-right pt-5 pt-sm-0'}>
                                    <div className={'col-title text-right'}>Следите за нами</div>
                                    <div className={'socials'}>
                                        {/* <Email url={'https://t.me/simbios_agency'}/> */}
                                        <a href="https://t.me/simbios_agency" target="_blank">
                                        <img className="telegram" src="https://cdn.discordapp.com/attachments/852870731544002562/1025416687161442394/telegram-f.svg"></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'row justify-content-center'}>
                        <div className={'col col-12 col-sm-6'}>
                            <p>Copyright © 2022 Simbios Agency. All rights reserved. | Проспект Ленина, 1 IT-Park, 5 Этаж, 512 каб. | г.Якутск | Мы открыты: 10:00 – 22:00, без выходных | Почта: simbiosykt@gmail.com | Тел: +7 924 562-93-77</p>
                        </div>
                    </div>

                    <div className={'row justify-content-center copyright'}>
                        <div className={'col col-12 col-sm-6 text-center'}>
                            <p>Cайт разработан с <i className={'icon-heart'}/> by <a href="https://vk.com/createandchoose">Aytal Popov</a></p>
                            <a href="https://tpykt.ru/">
                            <img src="https://qwantum.ru/wp-content/uploads/2021/07/tpykt-resident-white.svg"></img>    
                            </a>
                            
                        </div>
                        
                    </div>


                </div>
                <ReactModal
                    isOpen={this.state.isModalOpen}
                >
                    <div className={'inner'}>
                        <h3 className={'text-center'}>{this.state.popupTitle}</h3>
                        <div className={'text-center'} dangerouslySetInnerHTML={{
                            __html: this.state.msg
                        }}/>
                        <button className={'close-button'} onClick={this.handleModalClose}><i className={'icon-close'} aria-label="Close"></i></button>
                    </div>
                </ReactModal>
            </footer>
        )
    }
}

export default Footer
